
@font-face {
  font-display: swap;
  font-family: 'Bree Serif';
  font-style: normal;
  font-weight: 400;
  src: local('Bree Serif Regular'), local('BreeSerif-Regular'), url('/fonts/bree-serif/breeserif-regular.woff2') format('woff2'), url('/fonts/bree-serif/breeserif-regular.woff') format('woff'), url('/fonts/bree-serif/breeserif-regular.ttf') format('ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Courgette';
  font-style: normal;
  font-weight: 400;
  src: local('Courgette Regular'), local('Courgette-Regular'), url('/fonts/courgette/courgette-regular.woff2') format('woff2'), url('/fonts/courgette/courgette-regular.woff') format('woff'), url('/fonts/courgette/courgette-regular.ttf') format('ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  src: local('Bebas Neue Regular'), local('Bebas-Neue-Regular'), url('/fonts/bebas-neue/bebasneue-regular.woff2') format('woff2'), url('/fonts/bebas-neue/bebasneue-regular.woff') format('woff'), url('/fonts/bebas-neue/bebasneue-regular.ttf') format('ttf');
}
