.fence {
	position: absolute;
	top: 286px;
	display: none;
	width: 100vw;
	height: 146px;
	justify-content: center;
	padding: 0;
	margin: 0;

	$middle-width: 80vw;
	.sides {
		width: ((100vw - $middle-width) / 2);
		background-image: url('/images/fencepost.svg');
		background-position: right;
		background-repeat: repeat-x;
		background-size: contain;

		&.sideRight {
			background-position: left;
		}
	}

	.middle {
		width: $middle-width;
	}

	@media #{$viewport-lg} {
		display: flex;
	}

	@media #{$viewport-xl} {
		$middle-width: 60vw;
		.sides {
			width: ((100vw - $middle-width) / 2);
		}
		.middle {
			width: $middle-width;
		}
	}
}
