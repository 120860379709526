.itemInfoStage {
	position: relative;
	top: 0;
	width: 100vw;

	@media #{$viewport-sm} {
		width: 500px;
		margin: 40px auto;
	}

	@media #{$viewport-md} {
		top: 0;
		margin: 20px auto;
	}

	.itemInfo {
		position: relative;
		width: 100vw;
	  margin: 20px auto;

		@media #{$viewport-sm} {
			width: 500px;
		}

		.content {
			$content-height-xs: 215px;
			position: relative;
			width: 100%;
			height: $content-height-xs;
			margin: auto;
			background-image: url('/images/infocard-sliced-middle.svg');
			background-repeat: repeat-x;
			background-size: contain;
			opacity: 0;
			transition: opacity 0.5s linear;

			$content-sides-width: 41px;
			&:before {
				position: absolute;
				top: 0;
				left: -($content-sides-width) + 1;
				display: none;
				width: $content-sides-width;
				height: $content-height-xs;
				background-image: url('/images/infocard-sliced-left.svg');
				background-repeat: no-repeat;
				content: '';
			}

			&:after {
				position: absolute;
				top: 0;
				right: -($content-sides-width);
				display: none;
				width: $content-sides-width;
				height: $content-height-xs;
				background-image: url('/images/infocard-sliced-right.svg');
				background-repeat: no-repeat;
				content: '';
			}

			@media #{$viewport-sm} {
				max-width: 310px;

				&:before,
				&:after {
					display: block;
				}
			}

			@media #{$viewport-md} {
				max-width: 400px;
				height: 229px;

				&:before,
				&:after {
					width: 43px;
					height: 245px;
				}
			}
		}

		.contentItem {
			$contentItemFadeTime: 0.5s;
			position: absolute;
			top: 14px;
			display: block;
			width: 95%;
			height: 170px;
			padding: 4px 10px;
			background-color: $swatch-gray;
			color: $swatch-black;
			opacity: 1;
			text-align: left;

			&.contentItem-enter-active:not(.previous),
			&.contentItem-enter-done:not(.previous) {
				opacity: 0;
			}

			&.contentItem-exit-active:not(.previous) {
				animation: fadeIn $contentItemFadeTime linear 1;
			}

			&.previous {
				opacity: 0;

				&.contentItem-enter-active {
					animation: fadeOut $contentItemFadeTime linear 1;
				}
			}

			@media #{$viewport-sm} {
				width: 300px;
				padding: 4px;
			}
			@media #{$viewport-md} {
				width: 377px;
			}

			.title {
				padding: 0;
				margin: 0;
				margin-bottom: 10px;
				color: $swatch-blue-navy;
				font-size: 18px;

				@media #{$viewport-md} {
					font-size: 24px;
				}
			}

			.description {
				padding: 0;
				margin: 0;
				font-size: 16px;
				line-height: 1.3;
			}

			.actions {
				position: absolute;
				bottom: 0;
				display: flex;
				width: 93vw;
				justify-content: space-between;
				text-align: center;

				.buttonItem {
					width: 48%;
					background-color: $swatch-blue-mid;
					color: $swatch-white;

					.btn-share-hoverin {
						display: none;
						padding: 0;
						margin: 4px 0;
						list-style: none;
						list-style-position: inside;

						li {
							display: inline-block;
							padding: 2px 10px 0;
							font-size: 26px;
						}
					}

					.shareNoHover {
						cursor: pointer;
					}

					&:hover {
						background-color: $swatch-blue-navy;

						.btn-share-hoverout {
							display: none;
						}

						.btn-share-hoverin {
							display: inline-block;
						}
					}
				}

				@media #{$viewport-sm} {
					width: 100%;
					max-width: 306px;
				}
				@media #{$viewport-md} {
					max-width: 380px;
				}
			}

			@media #{$viewport-md} {
				height: (205px - 40px);
				padding: 10px 10px;
			}
		}

		.btn {
			width: 100%;
			padding: 0;
			border: none;
			background-color: transparent;
			color: $swatch-white;
			font-family: 'Bebas Neue', 'Arial Black', Gadget, sans-serif;
			font-size: 26px;
			text-transform: uppercase;

			&:focus {
				outline: none;
			}

			&.btn-share {
				padding: 8px 0 4px;
				font-size: 22px;

				@media #{$viewport-sm} {
					padding: 8px 0 4px;
					font-size: 26px;
				}
			}

		}
		a.btn {
			display: table;
			padding: 8px 0 4px;
			text-decoration: none;
		}
		button.btn {
			display: table;
			text-decoration: none;
		}

		.cordStage {
			position: absolute;
			top: -80px;
			left: -70px;
			display: none;
			width: 160px;
			height: 280px;
			background-image: url('/images/cord.svg');
			background-repeat: no-repeat;
			background-size: contain;
			opacity: 0;

			@media #{$viewport-md} {
				display: block;
			}
		}
	}

	&.active {
		.itemInfo {
			.content {
				opacity: 1;
			}
			.cordStage {
				opacity: 1;
			}
		}
	}

	@keyframes fadeIn {
		from {
			display: block;
			opacity: 0;
		}

		to {
			display: block;
			opacity: 1;
		}
	}

	@keyframes fadeOut {
		from {
			display: block;
			opacity: 1;
		}

		to {
			display: block;
			opacity: 0;
		}
	}

}
