// Basics
$swatch-black: #000000;
$swatch-white: #ffffff;
$swatch-gray: #eaeaea;
$swatch-gray-dark: #a0a09f;

// Accents
$swatch-red: #c60e2d;
$swatch-red-dark: #930f2b;
$swatch-green: #3e8922;
$swatch-brown-light: #72251c;
$swatch-brown: #4c1207;
$swatch-tan: #ce7717;
$swatch-sand: #dbcb86;

$swatch-blue: #329cfc;
$swatch-blue-mid: #2c84c6;
$swatch-blue-navy: #0d1a99;
$swatch-blue-dark: #08104c;
