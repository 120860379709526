.header {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	background-color: $swatch-black;
	background-image: url('/images/banner-top.svg');
	background-position: center;
	background-repeat: repeat-x;
	background-size: auto 50px;
	text-align: center;

	.sides {
		display: none;
		min-width: 140px;
		font-size: 20px;

		@media #{$viewport-lg} {
			display: block;
		}
	}

	.subtitle {
		font-family: 'Courgette', cursive, sans-serif;
		font-size: 20px;
		text-align: right;
	}

	.shares {
		font-size: 28px;
		text-align: left;

		ul {
			padding: 0;
			margin: 0;
			list-style-position: inside;
			list-style-type: none;

			li {
				display: inline-block;
				margin-right: 10px;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.main {
		padding: 4px 0 0 0;
		border: 4px solid $swatch-white;
		border-top-style: none;
		margin: 0 40px;
		background-color: $swatch-black;
		border-radius: 0 0 10px 10px;
		cursor: pointer;
		font-family: 'Bebas Neue', 'Arial Black', Gadget, sans-serif;
		font-size: 20px;
		text-transform: uppercase;
		white-space: normal;

		.element {
			display: inline-block;
			vertical-align: middle;
		}

		.title {
			width: 250px;
			height: auto;
			margin: 0 10px;
		}

		@media #{$viewport-sm} {
			padding: 18px 20px 16px;
			font-size: 30px;
		}

		@media (min-width: 549px) {
			white-space: nowrap;
		}

		@media #{$viewport-lg} {

			.title {
				width: 350px;
				margin: 0 10px;
			}
		}
	}
}
