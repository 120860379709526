.mrDoDoHead {
	position: absolute;
	z-index: 120;
	top: 43px;
	left: -45%;
	display: none;

	#MrDoDoHead {
		position: relative;
		overflow: visible;
		width: 200px;

		#arm-left {
			position: relative;
			animation-duration: 2s;
			transform: translate(1px, -10px) rotate(0deg);
			transform-origin: 70% 50%;
			transition: all .3s cubic-bezier(0.4, 0, 1, 1);
		}

	}

	&.mrDoDoHead-enter,
	&.mrDoDoHead-enter-done {

		#MrDoDoHead {
			#arm-left {
				transform: translate(-29px, -3px) rotate(80deg)
			}
		}
	}


	@keyframes armWaveOn {
		0% {
			transform: translate(1px, -10px) rotate(0deg);
		}
		100% {
			transform: translate(-29px, -3px) rotate(80deg)
		}
	}

	@keyframes armWaveOff {
		0% {
			transform: translate(-29px, -3px) rotate(80deg)
		}
		100% {
			transform: translate(1px, -10px) rotate(0deg);
		}
	}

	@media #{$viewport-sm} {
		display: block;
	}
	@media #{$viewport-md} {
		left: -45%;
	}

}
