.hotdogMini {
	position: absolute;
	top: 50px;
	left: -18px;
	overflow: visible;
	width: 60px;
	transform: rotate(-11deg);

	.arm {
		animation: armsRotate 1s linear infinite;
	}

	.armRight {
		transform: rotate(0deg);
		transform-origin: 100% 15%;

		&:after {
			position: absolute;
			top: 100%;
			left: 0;
			width: 5px;
			height: 5px;
			background-color: #f0f;
			border-radius: 50%;
			content: '';
			transform: translate(-50%, -50%);
		}
	}

	.armLeft {
		transform: rotate(2deg);
		transform-origin: 100% 15%;
		&:after {
			position: absolute;
			top: 100%;
			left: 0;
			width: 5px;
			height: 5px;
			background-color: #f0f;
			border-radius: 50%;
			content: '';
			transform: translate(-50%, -50%);
		}
	}

	@keyframes armsRotate {
		0% {
			transform: rotate(0deg);
		}
		50% {
			transform: rotate(2deg);
		}
		100% {
			transform: rotate(0deg);
		}
	}

	.eye {
		.pupil {
			animation: eyesPanic 0.5s linear infinite;
			transform: translate(0px, 0px);
		}

	}

	@keyframes eyesPanic {
		0% {
			transform: translate(0px, 0px);
		}

		25% {
			transform: translate(-1px, 0px);
		}

		75% {
			transform: translate(1px, 0px);
		}

		100% {
			transform: translate(0px, 0px);
		}
	}
}
