$hotdog-main-width: 280px;

.hotdogElement {
	position: absolute;
	top: -40px;
	right: -($hotdog-main-width);
	display: none;

	#hotdog-main {
		overflow: visible;
		height: $hotdog-main-width;

		#bun {
			transform: rotate(5deg) translate(4px, 10px);
			transform-origin: 120% 50%;
		}

		#body-main {
			transform: rotate(5deg) translate(6px, 6px);
			transform-origin: 100% 50%;
		}

		#arm-left-group {
			animation: armLeftWave 1s linear infinite;
			transform: rotate(0) translate(0, 0);
		}

		@keyframes armLeftWave {
			0% {
				transform: rotate(0) translate(0, 0);
			}
			50% {
				transform: rotate(6deg) translate(13px, 0px);
			}
			100% {
				transform: rotate(0) translate(0, 0);
			}
		}

	}

	@media #{$viewport-lg} {
		display: block;
	}
}
