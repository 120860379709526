@import "base/viewport";
@import "base/swatches";
@import "base/fonts";

@import "components/header";
@import "components/footer";
@import "components/maindisplay";
@import "components/fence";
@import "components/screen";
@import "components/iteminfo";
@import "components/mrdodohead";
@import "components/hotdog-main";
@import "components/hotdog-mini";

html {
	width: 100vw;
	min-width: 320px;
	overflow-x: hidden;
}

body {
	width: 100vw;
	min-width: 320px;
	margin: 0 0 20px 0;
	background-color: $swatch-green;
	color: $swatch-white;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
	color: $swatch-white;
}

.App {
	text-align: center;
}

.debug {
	color: red;
}
