.screenAndGear {
	$screen-width: 400px;
	$screen-bg-width: 334px;
	$screen-width-xs: 310px;
	$screen-bg-width-xs: 310px;

	$gear-width: 130px;

	position: relative;
	display: inline-block;
	width: ($screen-bg-width-xs);
	height: 336px;
	padding-top: 10px;
	margin: auto;

	.screenMain {
		position: relative;
		z-index: 100;
		bottom: 0;
		height: 100%;
	}

	.screenOnly {
		position: absolute;
		top: 0;
		left: 0;
		width: $screen-width-xs;

		@media #{$viewport-sm} {
			width: $screen-width;
		}
	}

	.productStage {
		position: relative;
		top: 55px;
		left: 26px;
		overflow: hidden;
		width: 254px;
		height: 203px;
		border: 2px solid $swatch-black;

		@media #{$viewport-sm} {
			top: 69px;
			left: 31px;
			width: 334px;
			height: 266px;
		}
	}

	.stageBg {
		position: absolute;
		top: 0;
		left: 0;
		width: 254px;
		height: 203px;
		background-color: $swatch-white;

		.stageTempBG {
			display: none;
			width: 100%;
			height: 100%;
			background-color: $swatch-white;

			&.hasActive {
				display: block;
			}
		}

		.stageBgText {
			position: absolute;
			top: 6px;
			left: 0;
			display: flex;
			width: 254px;
			height: 190px;
			align-items: center;
			justify-content: center;

			font-family: 'Bree Serif', serif;
			font-size: 25px;
			line-height: 1.2;

			.content {
				padding: 0 10px;
			}
		}

		@media #{$viewport-sm} {
			width: 334px;
			height: 266px;

			.stageBgText {
				top: 40px;
				width: 334px;
				height: 190px;
				font-size: 30px;
			}

			.content {
				padding: 0 30px;
			}
		}
	}

	.productImage {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;

		&.previous {
			display: none;
			background-color: $swatch-white;

			&.active {
				display: block;
			}
		}

	}

	.transitionalFlag {
		position: relative;
		top: -316px;
		left: -12px;
		width: 270px;
		height: auto;
		transition: top .5s linear;

		&.transitionalFlag-enter,
		&.transitionalFlag-enter-done {
			top: -60px;
		}

		@media #{$viewport-sm} {
			top: -420px;
			left: -12px;
			width: 356px;
		}

		@media #{$viewport-md} {
			top: -470px;
			left: -12px;
			width: $screen-width;
		}
	}

	.newCrapZone {
		position: absolute;
		z-index: 100;
		bottom: 10px;
		left: 394px;
		display: none;
		padding: 0;
		border: none;
		appearance: none;
		background-color: transparent;
		color: $swatch-white;
		cursor: pointer;

		&:focus {
			outline: none;
		}

		@media #{$viewport-md} {
			display: flex;
		}
	}

	@media #{$viewport-sm} {
		width: ($screen-width);
		height: 366px;
	}

	.gearMain {
		z-index: 10;
		user-select: none;

		.gearShaft {
			height: 225px;

			#shaft {
				transform: scale(1, 1);
				transform-origin: 50% 80%;
				transition: transform .3s cubic-bezier(0.4, 0, 1, 1);
			}

			#handle {
				transform: translate(0, 0);
				transition: transform .3s cubic-bezier(0.4, 0, 1, 1);
			}

			&.gearShaft-enter,
			&.gearShaft-enter-done {

				#shaft {
					transform: scale(1, .5);
				}
				#handle {
					transform: translate(0, 50px);
				}
			}
		}
	}

	.callToAction {
		margin-top: 10px;
		margin-left: 10px;
		opacity: 1;
		text-align: left;
		transition: opacity .3s cubic-bezier(0.4, 0, 1, 1);

		&.callToAction-enter,
		&.callToAction-enter-done {
			opacity: 0;
		}

		.container {
			display: flex;
			align-items: baseline;
		}

		#callToActionArrow {
			width: 12px;
		}

		.ctaText {
			width: 110px;
			padding-left: 10px;
			font-family: 'Bebas Neue', 'Arial Black', Gadget, sans-serif;
			font-size: 40px;
			line-height: 1;
		}
	}

	.mobileOnlyButton {
		position: absolute;
		bottom: 0;
		display: block;
		width: ($screen-width-xs);
		padding: 0 0 10px 0;
		background-color: $swatch-red;

		.btn {
			width: 254px;
			max-width: ($screen-bg-width + 2px);
			padding: 10px;
			border: 2px solid $swatch-white;
			margin: 10px;
			background-color: $swatch-blue-mid;
			color: $swatch-white;
			font-family: 'Bebas Neue', 'Arial Black', Gadget, sans-serif;
			font-size: 26px;
			text-transform: uppercase;

			&:focus {
				outline: none;
			}
		}

		@media #{$viewport-sm} {
			bottom: -40px;
			width: 100%;
			padding: 0 0 10px 0;

			.btn {
				width: 100%;
			}
		}

		@media #{$viewport-md} {
			display: none;
		}
	}
}
