.mainDisplay {
  min-height: 300px;
  background-color: $swatch-black;
  background-image: url('/images/grass.svg');
  background-position: 0 101%;
  background-repeat: repeat-x;
  background-size: 150px auto;
  // background-image: url('/images/fencepost.svg'), url('/images/grass.svg');
  // background-size: 40px auto, 200px auto;
  // background-position: 0 90%, 0 101%;
}
