.footer {
	max-width: 400px;
	padding: 0;
	margin: 0 auto;
	background-color: $swatch-green;
	font-family: sans-serif;
	font-size: 12px;
	font-weight: bold;
	line-height: 1.4;

	p {
		padding: 0;
		margin: 0;
	}
}
